import { useEffect, useState } from "react";
import { Dropdown, Image, Layout, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi, faWifiSlash } from "@fortawesome/pro-solid-svg-icons";
import { faPowerOff } from "@fortawesome/pro-regular-svg-icons";
import { Network } from "@capacitor/network";

import {
	apiUrl,
	defaultProfile,
	role,
	userData,
} from "../../providers/companyInfo";
import { checkNetworkStatus } from "../../providers/useCapacitorStorage";

export default function Header(props) {
	const { width, pageHeaderClass, pageHeaderIcon, title, pageId, subtitle } =
		props;

	const [networkStatus, setNetworkStatus] = useState(false);

	useEffect(() => {
		Network.addListener("networkStatusChange", (res) => {
			setNetworkStatus(res.connected);
		});

		checkNetworkStatus().then((res) => {
			setNetworkStatus(res.connected);
		});

		return () => {};
	}, []);

	const [profileImage, setProfileImage] = useState(defaultProfile);

	useEffect(() => {
		if (userData() && userData().profile_picture) {
			let profileImage = userData().profile_picture.split("//");
			if (profileImage[0] === "http:" || profileImage[0] === "https:") {
				setProfileImage(userData().profile_picture);
			} else {
				setProfileImage(apiUrl(userData().profile_picture));
			}
		}

		return () => {};
	}, []);

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("userdata");
		window.location.reload();
	};

	const menuProfile = () => {
		const items = [
			{
				key: "/account/details",
				className: "menu-item-profile-details",
				label: (
					<div className="menu-item-details-wrapper">
						<Image
							preview={false}
							src={profileImage}
							alt={userData().username}
						/>

						<div className="info-wrapper">
							<Typography.Text className="info-username">
								{userData().firstname} {userData().lastname}
							</Typography.Text>

							<br />
							<Typography.Text className="info-role">{role()}</Typography.Text>
						</div>
					</div>
				),
			}, // remember to pass the key prop
			// {
			// 	key: "/edit-profile",
			// 	icon: <FontAwesomeIcon icon={faEdit} />,
			// 	label: <Link to="/edit-profile">Edit Account Profile</Link>,
			// }, // which is required
		];

		items.push({
			key: "/signout",
			className: "ant-menu-item-logout",
			icon: <FontAwesomeIcon icon={faPowerOff} />,
			label: <Typography.Link onClick={handleLogout}>Sign Out</Typography.Link>,
		});

		return { items };
	};

	return (
		<Layout.Header>
			<div className="header-left-menu">
				{width > 320 ? (
					<PageHeader
						className={pageHeaderClass}
						title={
							<>
								<div className="ant-page-header-icon">
									<FontAwesomeIcon icon={pageHeaderIcon} />
								</div>
								<div className="ant-page-header-text">
									<div className="sub-title" id="pageHeaderSubtitle">
										{pageId !== "PageFacultyMonitoring"
											? subtitle
											: `Hi ${userData().username}!`}
									</div>
									<div className="title">{title}</div>
								</div>
							</>
						}
					/>
				) : (
					<PageHeader
						className={pageHeaderClass}
						title={
							<>
								<div className="ant-page-header-text">
									<div className="sub-title" id="pageHeaderSubtitle">
										{pageId !== "PageFacultyMonitoring"
											? subtitle
											: `Hi ${userData().username}!`}
									</div>
									<div className="title">{title}</div>
								</div>
							</>
						}
					/>
				)}
			</div>

			<div className="header-right-menu">
				<Dropdown
					menu={menuProfile()}
					placement="bottomRight"
					overlayClassName="menu-submenu-profile-popup"
					trigger={["click"]}
				>
					<Image
						preview={false}
						rootClassName="menu-submenu-profile"
						src={profileImage}
						alt={userData().username}
					/>
				</Dropdown>

				<div
					className={`icon-menu-network ${
						networkStatus ? "online" : "offline"
					}`}
				>
					<FontAwesomeIcon icon={networkStatus ? faWifi : faWifiSlash} />
				</div>
			</div>
		</Layout.Header>
	);
}
