import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { Button, Card, Form, Layout, Typography, Modal } from "antd";
import dayjs from "dayjs";

import { POST } from "../../../providers/useAxiosQuery";
import { description, encrypt, logo } from "../../../providers/companyInfo";
import { checkNetworkStatus } from "../../../providers/useCapacitorStorage";
import validateRules from "../../../providers/validateRules";
import FloatInput from "../../../providers/FloatInput";
import FloatInputPassword from "../../../providers/FloatInputPassword";

export default function PageLogin() {
	const { mutate: mutateLogin, isLoading: isLoadingButtonLogin } = POST(
		"api/login",
		"login"
	);

	const onFinishLogin = (values) => {
		checkNetworkStatus().then((res) => {
			if (res.connected) {
				let data = { ...values, from: "faculty_monitoring_attendance_checker" };
				mutateLogin(data, {
					onSuccess: (res1) => {
						if (res1.data) {
							localStorage.userdata = encrypt(JSON.stringify(res1.data));
							localStorage.token = res1.token;
							window.location.reload();
						} else {
							Modal.error({
								centered: true,
								transitionName: "zoom",
								title: "Username or Password Error",
								content: res.message,
								footer: null,
							});
						}
					},
					onError: () => {
						Modal.error({
							centered: true,
							transitionName: "zoom",
							title: "Username or Password Error",
							content: <>Unrecognized username or password. </>,
							footer: null,
						});
					},
				});
			} else {
				Modal.error({
					centered: true,
					transitionName: "zoom",
					title: "Connection Error",
					content: (
						<>
							Please connect to the internet and try again.{" "}
							<Button
								type="link"
								className="btn-edit p-0"
								icon={<FontAwesomeIcon icon={faArrowsRotate} />}
								onClick={() => window.location.reload()}
							/>
						</>
					),
					footer: null,
				});
			}
		});
	};

	return (
		<Layout.Content>
			<div className="main-content">
				<div className="left-content">
					<div className="content-wrapper">
						<div className="logo-wrapper zoom-in-out-box-1">
							<img src={logo} alt="" />
						</div>

						<div className="title">
							<div className="name">FSUU</div>
							<div className="description">
								Father Saturnino Urios University
							</div>
						</div>
					</div>
				</div>

				<div className="right-content">
					<Card className="card-login">
						<Typography.Title level={4} className="mt-0 mb-20 text-center">
							Account Login
						</Typography.Title>

						<Form onFinish={onFinishLogin} autoComplete="off">
							<Form.Item name="email" rules={[validateRules.required]}>
								<FloatInput
									label="Username / E-mail"
									placeholder="Username / E-mail"
								/>
							</Form.Item>

							<Form.Item name="password" rules={[validateRules.required]}>
								<FloatInputPassword label="Password" placeholder="Password" />
							</Form.Item>

							<Button
								type="primary"
								htmlType="submit"
								loading={isLoadingButtonLogin}
								className="mt-10 mb-10 btn-log-in"
								block
							>
								Log In
							</Button>

							{/* <Typography.Link href="login" target="_blank" italic>
						Forgot Password?
					</Typography.Link> */}
						</Form>
					</Card>
				</div>
			</div>

			<Layout.Footer>
				<Typography.Text>
					{`© ${dayjs()} ${description}. All Rights
                        Reserved.`}
				</Typography.Text>
			</Layout.Footer>
		</Layout.Content>
	);
}
