import { Routes, Route } from "react-router-dom";
import {
	faAddressCard,
	faClipboardUser,
	faHome,
} from "@fortawesome/pro-regular-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import PageLogin from "../views/public/PageLogin/PageLogin";

import PageProfile from "../views/private/PageProfile/PageProfile";
import PageFacultyMonitoringPendingList from "../views/private/PageFacultyMonitoringPendingList/PageFacultyMonitoringPendingList";
import PageFacultyMonitoring from "../views/private/PageDashboard/PageFacultyMonitoring";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/faculty-monitoring/pending-list"
				element={
					<PrivateRoute
						title="Faculty Monitoring"
						subtitle="SUPER ADMIN"
						pageId="PageFacultyMonitoringPendingList"
						pageHeaderIcon={faHome}
						component={PageFacultyMonitoringPendingList}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						title="PMO Monitoring"
						subtitle="SUPER ADMIN"
						pageId="PageFacultyMonitoring"
						pageHeaderIcon={faClipboardUser}
						component={PageFacultyMonitoring}
					/>
				}
			/>

			<Route
				path="/view-profile"
				element={
					<PrivateRoute
						moduleName="View Profile"
						title="Profile"
						subtitle="ACCOUNT"
						pageId="PageUserProfile"
						pageHeaderIcon={faAddressCard}
						component={PageProfile}
					/>
				}
			/>
		</Routes>
	);
}
