import { Row, Col, Image, Card } from "antd";
import { useEffect, useState } from "react";
import { apiUrl, userData } from "../../../providers/companyInfo";

export default function PageEditProfile() {
	const [profilePicture, setProfilePicture] = useState(null);

	useEffect(() => {
		console.log("userData()", userData());
		if (userData().profile_picture) {
			let profile_picture = userData().profile_picture.split("//");

			if (profile_picture[0] === "http:" || profile_picture[0] === "https:") {
				setProfilePicture(userData().profile_picture);
			} else {
				setProfilePicture(apiUrl() + userData().profile_picture);
			}
		}

		return () => {};
	}, []);

	return (
		<Card className="card-account-profile-info">
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
					<Image src={profilePicture} />
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<h3 className="text-center m-0">{`${userData().firstname} ${
						userData().lastname ?? ""
					}`}</h3>

					<h5 className="text-center m-0">{`${userData().role}`}</h5>
				</Col>
			</Row>
		</Card>
	);
}
