import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/pro-regular-svg-icons";
import { Card, Table, Button, notification, Row, Col, Typography } from "antd";

import { POST } from "../../../providers/useAxiosQuery";
import {
	checkNetworkStatus,
	readFile,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import notificationErrors from "../../../providers/notificationErrors";

export default function PageFacultyMonitoringPendingList() {
	const [dataSource, setDataSource] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	useEffect(() => {
		readFile("faculty_load_attendance_checker/faculty_load_status.txt").then(
			async (res) => {
				if (res && res.data) {
					res = JSON.parse(res.data);
					setDataSource(res);
				}
			}
		);

		return () => {};
	}, []);

	const { mutate: mutateUpdateStatusBulk, loading: isLoadingUpdateStatusBulk } =
		POST(`api/faculty_load_status_bulk`, "faculty_load_list");

	const handleUploadBulk = () => {
		let data = [];
		selectedRowKeys.forEach((item) => {
			let findData = dataSource.find(
				(f) => f.faculty_load_schedule_id === item
			);
			if (findData) {
				data.push(findData);
			}
		});

		checkNetworkStatus().then((res) => {
			if (res.connected) {
				mutateUpdateStatusBulk(
					{
						data: {
							data: data,
							link_origin: process.env.REACT_APP_FACULTY_MONITORING_URL,
						},
					},
					{
						onSuccess: (res2) => {
							if (res2.success) {
								notification.success({
									message: "Faculty Load",
									description: res2.message,
								});

								setFileStorage(
									"faculty_load_attendance_checker",
									"faculty_load_status",
									[]
								).then(() => {
									setDataSource([]);
									setSelectedRowKeys([]);
								});
							} else {
								notification.error({
									message: "Faculty Load Pending",
									description: res2.message,
								});
							}
						},
						onError: (err) => {
							notificationErrors(err);
						},
					}
				);
			} else {
				notification.error({
					message: "Faculty Load Pending",
					description:
						"I'm sorry, but you are currently not connected to the internet. Please reconnect to the internet and attempt your request again.",
				});
			}
		});
	};

	const columns = [
		{
			title: "Department",
			dataIndex: "deparment",
			key: "deparment",
		},
		{
			title: "Faculty Name",
			dataIndex: "fullname",
			key: "fullname",
			type: "link",
		},
		{
			title: "Room",
			dataIndex: "room_code",
			key: "room_code",
		},
		{
			title: "Schedule",
			key: "schedule",
			render: (_, record) => {
				let schedule = `${record.time_in} - ${record.time_out} ${record.meridian} - ${record.day_schedule}`;

				return schedule;
			},
		},
		{
			title: "Date",
			dataIndex: "created_at",
			key: "created_at",
		},
	];

	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24}>
				<Typography.Title level={3} type="secondary" className="pending-title">
					Faculty Load Status Pending Upload...
				</Typography.Title>
			</Col>
			{selectedRowKeys.length > 0 ? (
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						icon={<FontAwesomeIcon icon={faUpload} />}
						className="btn-main-primary br-20"
						loading={isLoadingUpdateStatusBulk}
						onClick={handleUploadBulk}
					>
						Upload
					</Button>
				</Col>
			) : null}

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card>
					<Table
						dataSource={dataSource}
						columns={columns}
						rowKey="faculty_load_id"
						scroll={{ x: "max-content" }}
						rowSelection={{
							selectedRowKeys,
							onSelect: (record, selected, selectedRows) => {
								let selectedRowKeysCopy = [...selectedRowKeys];
								if (selected) {
									console.log("record.faculty_load_id", record.faculty_load_id);
									selectedRowKeysCopy.push(record.faculty_load_id);
								} else {
									selectedRowKeysCopy = selectedRowKeysCopy.filter(
										(f) => f !== parseInt(record.faculty_load_id)
									);
								}
								setSelectedRowKeys(selectedRowKeysCopy);
							},
							onSelectAll: (selected, selectedRows, changeRows) => {
								let selectedRowsFiltered = selectedRows.map(
									(item) => item.faculty_load_id
								);
								if (selected) {
									if (selectedRowKeys.length !== selectedRowsFiltered.length) {
										setSelectedRowKeys(selectedRowsFiltered);
									} else {
										setSelectedRowKeys([]);
									}
								} else {
									setSelectedRowKeys([]);
								}
							},
						}}
					/>
				</Card>
			</Col>
		</Row>
	);
}
