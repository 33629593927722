import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Row,
	Button,
	Col,
	Table,
	Form,
	Select,
	notification,
	Badge,
	Input,
} from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";

import { GET } from "../../../providers/useAxiosQuery";
import {
	checkNetworkStatus,
	checkPermissions,
	readFile,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import ModalFormUpdateStatus from "./ModalFormUpdateStatus";

export default function PageFacultyMonitoring() {
	const [form] = Form.useForm();
	const navigation = useNavigate();

	const [dataSourceFacultyLoadStatus, setDataSourceFacultyLoadStatus] =
		useState([]);
	const [dataSourceFacultyLoad, setDataSourceFacultyLoad] = useState([]);
	const [dataSourceFacultyLoadAll, setDataSourceFacultyLoadAll] = useState([]);
	const [dataBuildings, setDataBuildings] = useState([]);
	const [dataFloors, setDataFloors] = useState([]);
	const [dataRooms, setDataRooms] = useState([]);
	const [dataStatus, setDataStatus] = useState([]);

	const [toggleModalUpdateStatus, setToggleModalUpdateStatus] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		sort_field: "",
		sort_order: "",
		from: "page_monitoring",
		building_id: "",
		floor_id: "",
		room_id: "",
		meridian: "",
	});

	GET(
		`api/faculty_load_schedule?from=page_monitoring`,
		"faculty_load_list",
		(res) => {
			if (res.data) {
				let data = res.data;

				data = data.map((item) => ({
					...item,
					date_sync: dayjs().format("YYYY-MM-DD"),
				}));

				readFile(
					"faculty_load_attendance_checker/faculty_load_status.txt"
				).then((res) => {
					if (res && res.data) {
						res = JSON.parse(res.data);

						setDataSourceFacultyLoadStatus(res);

						let newdata = [];

						data.forEach((item) => {
							let filterDataSourceFacultyLoad = res.filter(
								(f) =>
									f.faculty_load_schedule_id === item.id &&
									dayjs(f.date_sync).format("YYYY-MM-DD") ===
										dayjs().format("YYYY-MM-DD")
							);
							if (filterDataSourceFacultyLoad.length === 0) {
								newdata.push(item);
							}
						});

						setDataSourceFacultyLoad(newdata);
						setDataSourceFacultyLoadAll(newdata);

						checkPermissions().then((res1) => {
							if (res1) {
								if (res1.publicStorage === "granted") {
									setFileStorage(
										"faculty_load_attendance_checker",
										"faculty_load",
										newdata
									);
								} else {
									notification.error({
										message: "Permission",
										description:
											"Permission denied. Please allow this app to Read and Write a file to save the data.",
									});
								}
							}
						});
					} else {
						setDataSourceFacultyLoad(data);
						setDataSourceFacultyLoadAll(data);

						checkPermissions().then((res1) => {
							if (res1) {
								if (res1.publicStorage === "granted") {
									setFileStorage(
										"faculty_load_attendance_checker",
										"faculty_load",
										data
									);
								} else {
									notification.error({
										message: "Permission",
										description:
											"Permission denied. Please allow this app to Read and Write a file to save the data.",
									});
								}
							}
						});
					}
				});
			}
		}
	);

	GET(
		"api/ref_building",
		"building_select",
		(res) => {
			if (res && res.data) {
				let data = res.data;
				setDataBuildings(data);

				checkPermissions().then((res1) => {
					if (res1) {
						if (res1.publicStorage === "granted") {
							setFileStorage(
								"faculty_load_attendance_checker",
								"buildings",
								data
							);
						}
					}
				});
			}
		},
		false
	);

	GET(
		`api/ref_floor`,
		"floor_select",
		(res) => {
			if (res && res.data) {
				let data = res.data;
				setDataFloors(data);

				checkPermissions().then((res1) => {
					if (res1) {
						if (res1.publicStorage === "granted") {
							setFileStorage("faculty_load_attendance_checker", "floors", data);
						}
					}
				});
			}
		},
		false
	);

	GET(
		`api/ref_room`,
		"room_select",
		(res) => {
			if (res && res.data) {
				let data = res.data;
				setDataRooms(data);

				checkPermissions().then((res1) => {
					if (res1) {
						if (res1.publicStorage === "granted") {
							setFileStorage("faculty_load_attendance_checker", "rooms", data);
						}
					}
				});
			}
		},
		false
	);

	GET(
		`api/ref_status?status_category_code=SC-01&from=PageFacultyLoadMonitoring`,
		"status_select",
		(res) => {
			if (res && res.data) {
				let data = res.data;
				setDataStatus(data);
				checkPermissions().then((res1) => {
					if (res1) {
						if (res1.publicStorage === "granted") {
							setFileStorage(
								"faculty_load_attendance_checker",
								"statuses",
								data
							);
						}
					}
				});
			}
		},
		false
	);

	useEffect(() => {
		checkNetworkStatus().then((res) => {
			if (!res.connected) {
				let globalLoading = document.querySelector(".globalLoading");
				if (globalLoading && !globalLoading.matches(".hide")) {
					globalLoading.classList.add("hide");
				}

				checkPermissions().then((res1) => {
					if (res1) {
						if (res1.publicStorage === "granted") {
							readFile(
								"faculty_load_attendance_checker/faculty_load_status.txt"
							).then((res2) => {
								if (res2 && res2.data) {
									res2 = JSON.parse(res2.data);
									setDataSourceFacultyLoadStatus(res2);
								}
							});
							readFile("faculty_load_attendance_checker/faculty_load.txt").then(
								(res2) => {
									if (res2 && res2.data) {
										res2 = JSON.parse(res2.data);
										setDataSourceFacultyLoad(res2);
										setDataSourceFacultyLoadAll(res2);
									}
								}
							);
							readFile("faculty_load_attendance_checker/buildings.txt").then(
								(res2) => {
									if (res2 && res2.data) {
										res2 = JSON.parse(res2.data);
										setDataBuildings(res2);
									}
								}
							);
							readFile("faculty_load_attendance_checker/floors.txt").then(
								(res2) => {
									if (res2 && res2.data) {
										res2 = JSON.parse(res2.data);
										setDataFloors(res2);
									}
								}
							);
							readFile("faculty_load_attendance_checker/rooms.txt").then(
								(res2) => {
									if (res2 && res2.data) {
										res2 = JSON.parse(res2.data);
										setDataRooms(res2);
									}
								}
							);
							readFile("faculty_load_attendance_checker/statuses.txt").then(
								(res2) => {
									if (res2 && res2.data) {
										res2 = JSON.parse(res2.data);
										setDataStatus(res2);
									}
								}
							);
						}
					}
				});
			}
		});

		return () => {};
	}, []);

	const onChangeTable = (pagination, filters, sorter) => {
		setTableFilter((ps) => ({
			...ps,
			sort_field: sorter.columnKey,
			sort_order: sorter.order ? sorter.order.replace("end", "") : null,
			page: 1,
			page_size: "50",
		}));
	};

	const handleTriggerDebounce = debounce((field, value) => {
		let tableFilterCopy = { ...tableFilter };

		tableFilterCopy[field] = value;

		let dataSourceFacultyLoadAllTemp = [...dataSourceFacultyLoadAll];

		dataSourceFacultyLoadAllTemp = dataSourceFacultyLoadAll.filter((f) => {
			let building_id = tableFilterCopy.building_id;
			let floor_id = tableFilterCopy.floor_id;
			let room_id = tableFilterCopy.room_id;
			let meridian = tableFilterCopy.meridian;
			let search = tableFilterCopy.search.toLowerCase();

			return (
				(!building_id || f.building_id === building_id) &&
				(!floor_id || f.floor_id === floor_id) &&
				(!room_id || f.room_id === room_id) &&
				(!meridian || f.meridian === meridian) &&
				(!search ||
					f.fullname.toLowerCase().includes(search) ||
					f.time_in.toLowerCase().includes(search) ||
					f.time_out.toLowerCase().includes(search) ||
					f.dayschedule.toLowerCase().includes(search) ||
					f.dayschedule.toLowerCase().includes(search) ||
					f.school_year.toLowerCase().includes(search) ||
					f.semester.toLowerCase().includes(search))
			);
		});

		setDataSourceFacultyLoad(dataSourceFacultyLoadAllTemp);

		setTableFilter(tableFilterCopy);
	}, 1000);

	const handleDebounce = useCallback(
		(field, value) => {
			handleTriggerDebounce(field, value);
		},
		[handleTriggerDebounce]
	);

	return (
		<Row gutter={[12, 12]}>
			{dataSourceFacultyLoadStatus.length > 0 ? (
				<Col xs={24} sm={24} md={24} lg={24}>
					<Badge
						size="medium"
						className="pulse"
						count={dataSourceFacultyLoadStatus.length}
					>
						<Button
							onClick={() => {
								navigation("/faculty-monitoring/pending-list");
							}}
						>
							Faculty Load Status Pending Uplaod
						</Button>
					</Badge>

					{/* if offline add refresh button: hide */}
				</Col>
			) : (
				""
			)}

			<Col xs={24} sm={24} md={24} lg={24} className="filter-wrapper">
				<Form form={form}>
					<Row gutter={[12, 12]}>
						<Col xs={24} sm={12} md={8} lg={6}>
							<Form.Item name="building_id" className="m-0 1">
								<Select
									placeholder="Building"
									className="w-100 filter"
									size="large"
									showSearch
									allowClear
									options={
										dataBuildings
											? dataBuildings.map((item) => {
													return {
														label: item.building,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(value) => {
										handleDebounce("building_id", value);
										form.resetFields(["floor_id", "room_id", "meridian"]);
									}}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={8} lg={6}>
							<Form.Item name="floor_id" className="m-0 2">
								<Select
									placeholder="Floor"
									className="w-100 filter"
									size="large"
									allowClear
									options={
										dataFloors
											? dataFloors.map((item) => {
													return {
														label: item.floor,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(value) => {
										handleDebounce("floor_id", value);
										form.resetFields(["room_id", "meridian"]);
									}}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={8} lg={6}>
							<Form.Item name="room_id" className="m-0">
								<Select
									placeholder="Room"
									className="w-100 filter"
									size="large"
									allowClear
									options={
										dataRooms
											? dataRooms.map((item) => {
													return {
														label: item.room_code,
														value: item.id,
													};
											  })
											: []
									}
									onChange={(value) => {
										handleDebounce("room_id", value);
										form.resetFields(["meridian"]);
									}}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={8} lg={6}>
							<Form.Item name="meridian" className="m-0">
								<Select
									placeholder="Meridian"
									className="w-100 filter"
									size="large"
									allowClear
									options={[
										{ label: "AM", value: "AM" },
										{ label: "PM", value: "PM" },
									]}
									onChange={(value) => {
										handleDebounce("meridian", value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} md={8} lg={6}>
							<Form.Item name="search" className="m-0">
								<Input.Search
									placeholder="Search"
									className="w-100 filter"
									size="large"
									allowClear
									onChange={(value) => {
										handleDebounce("search", value.target.value);
									}}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Col>

			<Col xs={24} sm={24} md={24}>
				<Table
					className="ant-table-default ant-table-striped"
					dataSource={dataSourceFacultyLoad && dataSourceFacultyLoad}
					rowKey={(record) => record.id}
					pagination
					bordered={false}
					onChange={onChangeTable}
					scroll={{ x: "max-content" }}
					onRow={(record) => {
						return {
							onClick: () => {
								setToggleModalUpdateStatus({ open: true, data: record });
							},
						};
					}}
				>
					<Table.Column
						title="Name"
						key="fullname"
						sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
						render={(text, record) => (
							<Button
								type="link"
								className="color-1"
								onClick={() => {
									setToggleModalUpdateStatus({ open: true, data: record });

									console.log("record", record);
								}}
							>
								{record.fullname}
							</Button>
						)}
						width={120}
					/>

					<Table.Column
						title="Schedule"
						key="schedule"
						render={(_, record) => {
							let schedule = `${record.time_in} - ${record.time_out} ${record.meridian} - ${record.day_schedule}`;

							return schedule;
						}}
					/>

					<Table.Column
						title="Building"
						key="room_code"
						dataIndex="room_code"
						sorter={(a, b) => a.room_code.localeCompare(b.room_code)}
					/>
					<Table.Column
						title="Description of Assignment"
						key="subject_code"
						dataIndex="subject_code"
						sorter={(a, b) => a.subject_code.localeCompare(b.subject_code)}
					/>
					<Table.Column
						title="School Year"
						key="school_year"
						dataIndex="school_year"
						sorter={(a, b) => a.school_year.localeCompare(b.school_year)}
					/>
					<Table.Column
						title="Semester"
						key="semester"
						dataIndex="semester"
						sorter={(a, b) => a.semester.localeCompare(b.semester)}
					/>
				</Table>
			</Col>

			<ModalFormUpdateStatus
				toggleModalUpdateStatus={toggleModalUpdateStatus}
				setToggleModalUpdateStatus={setToggleModalUpdateStatus}
				dataStatus={dataStatus}
				setDataSourceFacultyLoad={setDataSourceFacultyLoad}
				dataSourceFacultyLoad={dataSourceFacultyLoad}
				setDataSourceFacultyLoadStatus={setDataSourceFacultyLoadStatus}
			/>
		</Row>
	);
}
