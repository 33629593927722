import React, { useEffect, useState } from "react";
import { Card, Layout, Button, notification } from "antd";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowsRotate,
	faGifts,
	faRefresh,
	faWifi,
	faWifiSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { Network } from "@capacitor/network";
import ClearCache from "react-clear-cache";

import { bgColor, name } from "../../providers/companyInfo";
import Header from "./Header";
import Footer from "./Footer";

export default function Private(props) {
	const { children, title, subtitle, pageHeaderIcon, pageHeaderClass, pageId } =
		props;

	console.log("Private props", props);

	const [width, setWidth] = useState(window.innerWidth);

	const [sideMenuCollapse, setSideMenuCollapse] = useState(
		window.innerWidth <= 768 ? true : false
	);

	useEffect(() => {
		Network.addListener("networkStatusChange", (res) => {
			let description = "";
			let class_status = "success-color";
			let icon = faWifi;

			if (res.connected) {
				description = (
					<>
						<span>Your internet connection was restored.</span>
						<Button
							type="dashed"
							icon={<FontAwesomeIcon icon={faArrowsRotate} />}
							onClick={() => {
								window.location.reload();
							}}
						>
							Refresh
						</Button>
					</>
				);
				class_status = "success-color";
				icon = faWifi;
			} else {
				description = "You are currently offline.";
				class_status = "";
				icon = faWifiSlash;
			}

			notification.info({
				message: "Internet Connection",
				description,
				placement: "bottomLeft",
				icon: <FontAwesomeIcon icon={icon} className={class_status} />,
				duration: 10,
			});
		});

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (title) {
			document.title = title + " | " + name;
		}

		function handleResize() {
			setWidth(window.innerWidth);

			if (window.innerWidth === 768) {
				setSideMenuCollapse(true);
			}
			if (window.innerWidth > 768) {
				setSideMenuCollapse(false);
			}
		}
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, [title]);

	return (
		<ClearCache>
			{({ isLatestVersion, emptyCacheStorage }) => (
				<>
					{!isLatestVersion && (
						<div className="notification-notice">
							<div className="notification-notice-content">
								<div className="notification-notice-icon">
									<FontAwesomeIcon icon={faGifts} />
								</div>
								<div className="notification-notice-message">
									<div className="title">Updates Now Available</div>
									<div className="description">
										A new version of this Web App is ready
									</div>
									<div className="action">
										<Button
											onClick={(e) => {
												e.preventDefault();
												emptyCacheStorage();
											}}
											icon={<FontAwesomeIcon icon={faRefresh} />}
										>
											Refresh
										</Button>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="globalLoading hide">
						<SpinnerDotted thickness="100" color={bgColor} enabled={true} />
					</div>

					<Layout className="private-layout" id={pageId ?? ""}>
						<Layout
							className={sideMenuCollapse ? "ant-layout-has-collapse" : ""}
						>
							<Header
								sideMenuCollapse={sideMenuCollapse}
								setSideMenuCollapse={setSideMenuCollapse}
								width={width}
								pageHeaderClass={pageHeaderClass}
								pageHeaderIcon={pageHeaderIcon}
								title={title}
								subtitle={subtitle}
							/>

							<Layout.Content
								onClick={() => {
									if (width <= 767) {
										setSideMenuCollapse(true);
									}
								}}
							>
								<Card>{children}</Card>
							</Layout.Content>

							<Footer />
						</Layout>
					</Layout>
				</>
			)}
		</ClearCache>
	);
}
