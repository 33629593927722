import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Button } from "antd";
import { faClipboardUser, faPaste } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Layout.Footer className="footer-container">
			<Button
				className={`icon monitor ${
					location.pathname === "/dashboard" ? "active" : ""
				}`}
				type="link"
				onClick={() => {
					navigate("/dashboard");
				}}
			>
				<FontAwesomeIcon icon={faClipboardUser} />
				<div className="label">Monitoring</div>
			</Button>
			<Button
				className={`icon monitor ${
					location.pathname === "/faculty-monitoring/pending-list"
						? "active"
						: ""
				}`}
				type="link"
				onClick={() => {
					navigate("/faculty-monitoring/pending-list");
				}}
			>
				<FontAwesomeIcon icon={faPaste} />
				<div className="label">Pending List</div>
			</Button>
		</Layout.Footer>
	);
}
