import { useEffect, useState } from "react";
import { Modal, Form, Radio, Space, Button, notification, Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";

import { POST } from "../../../providers/useAxiosQuery";
import { userData } from "../../../providers/companyInfo";
import {
	checkNetworkStatus,
	checkPermissions,
	readFile,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import imageResizer from "../../../providers/imageResizer";
import notificationErrors from "../../../providers/notificationErrors";
import validateRules from "../../../providers/validateRules";
import FloatTextArea from "../../../providers/FloatTextArea";

export default function ModalFormUpdateStatus(props) {
	const {
		toggleModalUpdateStatus,
		setToggleModalUpdateStatus,
		dataStatus,
		setDataSourceFacultyLoad,
		setDataSourceFacultyLoadStatus,
	} = props;
	const [form] = Form.useForm();

	const [fileList, setFileList] = useState([]);

	const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } = POST(
		`api/faculty_load_status`,
		"faculty_load_list"
	);

	const handleChangeUpload = async ({ fileList: newFileList }) => {
		setFileList([]);
		try {
			const modifiedFiles = await Promise.all(
				newFileList.map(async (item) => {
					const compressedImage = await imageResizer(item.originFileObj);
					const compressedImagebase64 = await imageResizer(
						item.originFileObj,
						"base64"
					);

					return {
						originFileObj: compressedImage,
						is_delete: 0,
						lastModified: compressedImage.lastModified,
						lastModifiedDate: compressedImage.lastModifiedDate,
						name: compressedImage.name,
						size: compressedImage.size,
						type: compressedImage.type,
						uid: item.uid,
						status: "done",
						base64: compressedImagebase64,
					};
				})
			);

			setFileList(modifiedFiles);
		} catch (error) {
			console.error("Error occurred:", error);
		}
	};

	const onFinish = (values) => {
		checkNetworkStatus().then((res) => {
			if (res.connected) {
				let data = new FormData();

				data.append(
					"faculty_load_schedule_id",
					toggleModalUpdateStatus.data.id
				);
				data.append(
					"faculty_load_id",
					toggleModalUpdateStatus.data.faculty_load_id
				);

				data.append("created_at", dayjs().format("YYYY-MM-DD HH:mm:ss"));
				data.append("updated_at", dayjs().format("YYYY-MM-DD HH:mm:ss"));
				data.append("created_by", userData().id);

				data.append("status_id", values.status_id);
				data.append("remarks", values.remarks);
				data.append(
					"link_origin",
					process.env.REACT_APP_FACULTY_MONITORING_URL
				);

				let fileCounter = 0;
				if (fileList.length > 0) {
					for (let x = 0; x < fileList.length; x++) {
						const elem = fileList[x];

						if (elem.originFileObj) {
							fileCounter++;
							data.append(`file_${x}`, elem.originFileObj, elem.name);
						}
					}
				}
				data.append("fileCounter", fileCounter);

				mutateUpdateStatus(data, {
					onSuccess: (res) => {
						// console.log("mutateFormUpload res", res);
						if (res.success) {
							notification.success({
								message: "Faculty Monitoring",
								description: res.message,
							});

							setToggleModalUpdateStatus({ open: false, data: null });
							setFileList([]);

							form.resetFields();
						} else {
							notification.error({
								message: "Faculty Monitoring",
								description: res.message,
							});
						}
					},
					onError: (err) => {
						// notification.error({
						// 	message: "Faculty Monitoring",
						// 	description: "Something Went Wrong",
						// });
						notificationErrors(err);
					},
				});
			} else {
				checkPermissions().then((res) => {
					if (res) {
						if (res.publicStorage === "granted") {
							let data = {
								...values,
								faculty_load_schedule_id: toggleModalUpdateStatus.data.id,
								faculty_load_id: toggleModalUpdateStatus.data.faculty_load_id,
								fullname: toggleModalUpdateStatus.data.fullname,
								building: toggleModalUpdateStatus.data.building,
								code: toggleModalUpdateStatus.data.code,
								date_sync: toggleModalUpdateStatus.data.date_sync,
								dayschedule: toggleModalUpdateStatus.data.dayschedule,
								deparment: toggleModalUpdateStatus.data.deparment,
								floor: toggleModalUpdateStatus.data.floor,
								room_code: toggleModalUpdateStatus.data.room_code,
								meridian: toggleModalUpdateStatus.data.meridian,
								school_year: toggleModalUpdateStatus.data.school_year,
								section: toggleModalUpdateStatus.data.section,
								semester: toggleModalUpdateStatus.data.semester,
								time_in: toggleModalUpdateStatus.data.time_in,
								time_out: toggleModalUpdateStatus.data.time_out,
								created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
								updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
								created_by: userData().id,
								fileList: JSON.stringify(fileList),
							};

							setFileStorage(
								"faculty_load_attendance_checker",
								"faculty_load_status",
								[data],
								true
							);
							setDataSourceFacultyLoadStatus((ps) => [...ps, data]);

							readFile("faculty_load_attendance_checker/faculty_load.txt").then(
								(res) => {
									if (res && res.data) {
										res = JSON.parse(res.data);

										let filterDataSourceFacultyLoad = res.filter(
											(f) =>
												f.id !== toggleModalUpdateStatus.data.id &&
												dayjs().format("YYYY-MM-DD") ===
													dayjs(f.data_sync).format("YYYY-MM-DD")
										);

										setDataSourceFacultyLoad(filterDataSourceFacultyLoad);
										setFileStorage(
											"faculty_load_attendance_checker",
											"faculty_load",
											filterDataSourceFacultyLoad
										);
									}
								}
							);

							notification.success({
								message: "Faculty Monitoring",
								description: "Data save in local",
							});

							setToggleModalUpdateStatus({ open: false, data: null });

							form.resetFields();
							setFileList([]);
						}
					}
				});
			}
		});
	};

	const handleRenderStatus = () => {
		if (dataStatus) {
			return dataStatus.map((item, index) => {
				return (
					<Radio value={item.id} key={index}>
						{item.status}
					</Radio>
				);
			});
		} else {
			return null;
		}
	};

	useEffect(() => {
		if (!toggleModalUpdateStatus.open) {
			form.resetFields();
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalUpdateStatus]);

	return (
		<Modal
			title={
				<>
					<b>
						{toggleModalUpdateStatus.data &&
							toggleModalUpdateStatus.data.fullname}
					</b>
				</>
			}
			open={toggleModalUpdateStatus.open}
			onCancel={() => {
				setToggleModalUpdateStatus({ open: false, data: null });
			}}
			footer={[
				<Button
					className="btn-main-primary outlined"
					onClick={() => {
						setToggleModalUpdateStatus({ open: false, data: null });
					}}
					key={1}
					disabled={isLoadingUpdateStatus}
				>
					CANCEL
				</Button>,
				<Button
					type="primary"
					className="btn-main-primary"
					onClick={() => {
						form.submit();
					}}
					key={2}
					loading={isLoadingUpdateStatus}
				>
					SUBMIT
				</Button>,
			]}
			forceRender
		>
			<div className="mb-10">
				<Upload.Dragger
					className="upload-w-100 upload-hide-remove-icon"
					accept="image/png,image/jpg,image/jpeg"
					multiple
					beforeUpload={(file) => {
						let error = false;
						const isLt2M = file.size / 102400 / 102400 < 5;
						if (!isLt2M) {
							// message.error("Audio must smaller than 5MB!");
							notification.error({
								// message: "Faculty Monitoring Justification",
								description: "Image must smaller than 5MB!",
							});
							error = Upload.LIST_IGNORE;
						}
						return error;
					}}
					onChange={handleChangeUpload}
					fileList={fileList}
				>
					<p className="ant-upload-drag-icon">
						<FontAwesomeIcon icon={faFileArrowUp} className="m-r-xs" />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to upload
					</p>
					<p className="ant-upload-hint">Support for a single or bulk upload</p>
				</Upload.Dragger>
			</div>

			<Form form={form} onFinish={onFinish}>
				<Form.Item name="status_id" rules={[validateRules.required]}>
					<Radio.Group>
						<Space direction="vertical">{handleRenderStatus()}</Space>
					</Radio.Group>
				</Form.Item>

				<Form.Item name="remarks" initialValue={"NA"}>
					<FloatTextArea placeholder="Remarks" label="Remarks" />
				</Form.Item>
			</Form>
		</Modal>
	);
}
